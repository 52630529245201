.assets-container {
  margin-top: 20px;
  height: 380px;
  padding: 25px 27px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}

.assets-title {
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 18px;
  line-height: 14px;
  color: #425466;
}

.numberdeals-container {
  height: auto;
  min-height: 380px;
}

.numberdeals-container {
  height: auto;
}
.numberdeals-table {
  margin-bottom: 20px;
}
