.navigation {
  padding: 26px;
  font-size: 14px;
}
.navigation span {
  cursor: pointer;
  padding: 0 8px;
}
.navigation .active {
  color: #0080FF;
}